import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { api_setor_publicacao } from '../constantes';
 
@Injectable()
export class SetoresAutorizadosResolver implements Resolve<any> {

  constructor(
    private http: HttpClient
  ) {}
 
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.http.get<any>(`${api_setor_publicacao}/todos`);
  }
  
}