import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { api_tipo_publicacao } from '../constantes';

 
@Injectable()
export class TiposPublicacaoResolver implements Resolve<any> {

  constructor(private http: HttpClient) {}
 
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.http.get<Array<any>>(`${api_tipo_publicacao}/todos`)
  }
}